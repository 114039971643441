<script setup lang="ts">
// import { watch } from "vue";
import { onKeyStroke } from "@vueuse/core";
import { KippieSearch, KippieIconButton } from "../";
import { ref } from "vue";

const emit = defineEmits<{
	(e: "update:modelValue", value: boolean): void;
	(e: "search", value: string): void;
	(e: "submit", value: string): void;
}>();

interface Props {
	modelValue: boolean;
	placeholder: string;
	live?: boolean;
}

const { live = true, modelValue, placeholder } = defineProps<Props>();

const search = ref();

// watch(
// 	() => modelValue,
// 	() => {
// 		if (modelValue && search.value) {
// 			search.value?.setFocus();
// 		}
// 	}
// );

onKeyStroke("Escape", () => emit("update:modelValue", false));
onKeyStroke(["k"], (e) => {
	if (e.metaKey || e.ctrlKey) {
		emit("update:modelValue", true);
	}
});
</script>

<template>
	<div />
	<Transition
		enter-active-class="duration-300 ease-in-out"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="duration-300 ease-in-out"
		leave-from-class="topacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="modelValue"
			class="fixed inset-0 z-40 cursor-pointer bg-black/40"
			@click="$emit('update:modelValue', false)"
		/>
	</Transition>
	<Transition
		enter-active-class="duration-300 ease-in-out"
		enter-from-class="-translate-y-full opacity-0"
		enter-to-class="opacity-100 translate-0"
		leave-active-class="duration-300 ease-in-out"
		leave-from-class="opacity-100 translate-0"
		leave-to-class="-translate-y-full opacity-0"
	>
		<div
			v-if="modelValue"
			class="fixed inset-0 z-50 max-h-screen p-6 space-y-6 overflow-auto bg-white md:left-1/2 md:-translate-x-1/2 lg:top-[14%] md:top-[3rem] md:right-auto md:bottom-auto md:rounded-2xl md:w-full md:max-w-xl lg:max-w-3xl"
			role="dialog"
			aria-modal="true"
		>
			<div class="flex items-center gap-x-4">
				<KippieSearch
					ref="search"
					:placeholder="placeholder"
					:live="live"
					focus
					@search="emit('search', $event)"
					@submit="emit('submit', $event)"
				/>
				<KippieIconButton @click="emit('update:modelValue', false)" />
			</div>

			<slot />
		</div>
	</Transition>
</template>
